<template>
    <div class="btn-group-chars" role="group" aria-label="Special Characters">
        <div v-for="code in validCharCodes"
            :key="code"
            :class="{ 'btn-char':true, 'active': code === selectedCharCode }"
            @click="selectedCharCode = code">
            <span>{{String.fromCharCode(code)}}</span>
        </div>
        <div class="btn-char-bookend"></div>
    </div>
</template>
<script>
    export default {
        name: "SpecialCharSelection",
        props: {
            modelValue: { type: String, default: String.fromCharCode(32) },
            charCode: { type: Number, default: 32 }
        },
        data () {
            return {
                selectedCharCode: this.modelValue.charCodeAt(0),
                selectedCharValue: this.modelValue
            };
        },
        computed: {
            validCharCodes() {
                let result = [];
                for(let i = 32; i < 255; i++) {
                    if(i >= 127 && i <= 160) continue;
                    result.push(i);
                }
                return result;
            }
        },
        watch:{
            selectedCharCode(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.selectedCharCode = newValue;
                this.selectedCharValue = String.fromCharCode(newValue);
                this.$emit("update:charCode", this.selectedCharCode);
                this.$emit("update:modelValue", this.selectedCharValue);
            }
        }
    }
</script>