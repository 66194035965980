<template>
    <div class="rq-container standard-language-dialog min-h-200">
        <standard-language-prompt-list
            v-if="promptEntryMode"
            ref="promptList"
            :promptInfo="promptInfo"
        />
        <standard-language-selection
            v-show="!promptEntryMode"
            :category-id="categoryId"
            :show-lines-between-clauses="showLinesBetweenClauses"
            :category-list="categories"
            :promptInfo="promptInfo"
            v-model:standard-language-selected="selectedStandardLanguages"
            v-model:lines-between-clauses="linesBetweenClauses"
            dialog-mode
            allow-duplicates
        />
        <Teleport
            v-if="dialogFooterAvailable"
            :to="`#${dialogFooterId}`">
            <div class="std-lang-footer">
                <b-btn v-show="promptEntryEnabled" automation_id="btn_dm_modal_back" variant="secondary" @click="onBack">Back</b-btn>
                <div class="btn-group">
                    <b-btn automation_id="btn_dm_modal_cancel" variant="secondary" @click="onCancel">Cancel</b-btn>
                    <span v-rq-tooltip.hover.top :title="okDisabled ? 'No clauses selected' : ''">
                        <b-btn automation_id="btn_dm_modal_ok" variant="primary" :disabled="okDisabled" @click="onOk">Ok</b-btn>
                    </span>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
    import { DocumentPromptInfo, DocumentPrompt, StandardLanguageModel, StandardLanguagePackageModel, DocumentSaveRequest } from '../../models';
    import StandardLanguagePromptList from './StandardLanguagePromptList';
    import StandardLanguageSelection from './StandardLanguageSelection';
    export default {
        components: {
            StandardLanguagePromptList,
            StandardLanguageSelection
        },

        props: {
            categoryId: { type: Number, default: 0 },
            categories: { type: Array, default: () => [] },
            skipPromptEntry: { type: Boolean, default: false },
            showLinesBetweenClauses: {type: Boolean, default: true}
        },

        inject: {
            dialogFooterId: { from: "footerId", default: null },
            dialogFooterAvailable: { from: "footerAvailable", default: false }
        },

        data() {
            const self = this;
            return {
                promptInfo: new DocumentPromptInfo(),
                promptEntryMode: false,
                selectedStandardLanguages: [],
                linesBetweenClauses: 1
            };
        },

        computed: {
            hasSelection() { return !_.isEmpty(this.selectedStandardLanguages); },
            promptEntryEnabled() { return this.promptEntryMode; },
            okDisabled() { return !this.hasSelection; }
        },

        created() {

        },

        methods: {
            // Note: this function is called from host component okHandler thats opening this modal.
            validate() {
                const self = this;
                let promptIsValid = !self.promptEntryMode || _.invoke(self, "$refs.promptList.validate");

                if (!promptIsValid) return Promise.resolve(false);
                return this.checkStandardLanguageSubPrompts();
            },

            checkStandardLanguageSubPrompts() {
                if (this.promptEntryMode) return Promise.resolve(true);

                return this.populateStandardLanguageList()
                    .then(() => {
                        if (this.skipPromptEntry) return true;

                        let standardLanguageList = _.get(this, "promptInfo.prompt.standardLanguageList", null) || [];
                        let subPromptsExist = _.some(standardLanguageList, sl => sl.prompts.length > 0);

                        if (subPromptsExist) {
                            this.promptEntryInstruction = "Some of the clauses you selected contain prompts.  Fill these out and click continue.";
                            this.promptEntryMode = true;
                        }
                        return !subPromptsExist;
                    });
            },

            onBack() { this.cancelPromptEntry(); },
            
            onCancel() { this.$emit("cancel"); },
            
            onOk() { this.$emit("ok"); },

            cancelPromptEntry() {
                this.promptEntryMode = false;
            },

            getResult() {
                const self = this;
                let standardLanguageList = _.get(self, "promptInfo.prompt.standardLanguageList", null) || [];
                let selectedStandardLanguages = _.filter(standardLanguageList, e => !_.getBool(e.isPackage));

                let items = _.map(selectedStandardLanguages, sl => {
                    sl.linesBetweenClauses = _.parseNumber(self.promptInfo.promptValue, 1);
                    return new StandardLanguageModel(sl);
                });

                let packages = _.filter(standardLanguageList, e => _.getBool(e.isPackage));
                let packageList = _.map(packages, pkg => StandardLanguagePackageModel.fromPackageItems(pkg.packageItems));

                return {
                    items,
                    packages: packageList,
                    linesBetweenClauses: self.linesBetweenClauses
                };
            },

            // Note: this function is called from host component okHandler thats opening this modal.
            dialogResult(){
                const self = this;
                return self.getResult();
            },

            contentResult(){
                const self = this;
                let result = self.getResult();
                let allStandardLanguages = _.concat(result.items, result.packages);
                let htmlPromises = _.map(allStandardLanguages, sl => {
                    if(!_.isNil(sl.html)) return Promise.resolve(sl);
                    return self.$api.DocumentsApi.getStandardLanguageContent(sl.standardLanguageID)
                        .then(result => {
                            sl.html = result;
                            return sl;
                        });
                });
                return self.$rqBusy.wait(Promise.all(htmlPromises));
            },

            getItemPrompts(standardLanguageItem) {
                const self = this;
                if(self.skipPromptEntry) return Promise.resolve([]);
                return self.$api.DocumentsApi.getStandardLanguageSubPrompts(standardLanguageItem, self.promptInfo.orderDocumentID, self.promptInfo.ordersID)
                    .then(result => {
                        standardLanguageItem.ordersID = self.promptInfo.ordersID;
                        standardLanguageItem.orderDocumentID = self.promptInfo.orderDocumentID;
                        standardLanguageItem.orderDocumentDescription = self.promptInfo.scopeDocumentDescription;
                        standardLanguageItem.prompts = _.map(result, p => {
                            let dpi = new DocumentPromptInfo(p);
                            dpi.ordersID = self.promptInfo.ordersID;
                            dpi.scopeDocumentDescription = self.promptInfo.scopeDocumentDescription;
                            return dpi;
                        });
                        return standardLanguageItem;
                    })
                    .catch(err => {
                        self.$toast.error(`The prompt request for clause, ${standardLanguageItem.description}, failed.  Change your selection and try again.`);
                        throw err;
                    });
            },

            populateStandardLanguageList() {
                const self = this;

                self.promptInfo.prompt.standardLanguageList = [];

                this.selectedStandardLanguages.forEach((rowData) => {
                    if(rowData.isPackage) {
                        rowData.packageItems.forEach(item => {
                            self.promptInfo.prompt.standardLanguageList.push(_.cloneDeep(item));
                        });
                    }
                    else {
                        self.promptInfo.prompt.standardLanguageList.push(_.cloneDeep(rowData));
                    }
                });

                let promptPromises = _.map(self.promptInfo.prompt.standardLanguageList, (i) => self.getItemPrompts(i));
                return self.$rqBusy.wait(Promise.all(promptPromises))
                    .catch(err => {
                        self.$toast.error("An issue occured while retrieving prompts for the selected clauses.");
                    });

            }
        }

    };
</script>
