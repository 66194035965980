<template>
    <div class="rq-watermark-config content-wrapper" v-if="hasData">
        <rq-page-section title="Text Choices">
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add_text_choice" variant="theme" @click="onAddTextChoice">Add Text Choice</b-btn>
                    </li>
                </ul>
            </template>
            <rqdx-action-data-grid
                ref="watermarkTextDataGrid"
                automation_id="tbl_watermark_text"
                :actions="selectionActions"
                :data-source="gridDataSource"
                :config="gridConfig"
                export-file-name="watermark-data"
                @delete="onDeleteAction"
                hide-search
                rq-editable
                hide-show-column-chooser
                hide-clear-filters
            />
        </rq-page-section>
        <rq-page-section title="Watermark Options" borderless>
            <fieldset>
                <div class="row">
                    <div class="col col-12 form-group">
                        <label class="form-control-label" for="dpg_font">Font</label>
                        <dx-select-box
                            :input-attr="$utils.idAttrs('dpg_font')"
                            :items="watermarkData.fonts"
                            item-template="item"
                            field-template="field"
                            v-model="watermarkData.watermark.font">
                            <template #field="{ data }">
                                <div><dx-text-box :value="data" /></div>
                            </template>
                            <template #item="{ data }">
                                <div><div :style="{ 'font-family': data }">{{data}}</div></div>
                            </template>
                        </dx-select-box>
                    </div>
                    <div class="col col-12 col-lg-5 form-group">
                        <label class="form-control-label" for="cbo_size">Size</label>
                        <dx-select-box
                            :input-attr="$utils.idAttrs('cbo_size')"
                            :items="watermarkData.sizes"
                            v-model="watermarkData.watermark.size"
                        />
                    </div>
                    <div class="col col-12 col-lg-7 form-group">
                        <label for="color-picker">Color</label>
                        <dx-color-box v-model="watermarkData.watermark.color" />
                    </div>
                    <div class="col col-auto form-group">
                        <label class="form-control-label" for="chk_layout">Layout</label>
                        <b-form-checkbox
                            automation_id="chk_layout"
                            id="chk_layout"
                            v-model="watermarkData.watermark.horizontal" >Horizontal</b-form-checkbox>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
    </div>
</template>
<script>
    import DxColorBox from "devextreme-vue/color-box";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import {DocumentWatermarksModel, DocumentWatermarkModel, DocumentWatermarkTextModel} from "./models";

    export default {
        name: "WatermarkForm",
        components: { DxColorBox },
        props: {
            documentTemplateId: { type: Number, default: 0 }
        },
        data(){
            return {
                watermarkData: null,
                deletedTextItems: []
            }
        },
        created(){
            this.loadFontConfig();
            this.loadGridConfig();
            this.fetchData();
        },
        computed:{
            hasData(){ return !_.isNil(this.watermarkData);},
            watermarkTextGrid(){ return _.get(this, "$refs.watermarkTextDataGrid.gridInstance", null) || {}; },
            dxThemeClass() {
                let routeTheme = _.get(this, "$route.meta.theme", null);
                return routeTheme ? `rq-dx-theme-${routeTheme}` : "";
            },
            sizes(){
                return _.map(this.watermarkData.sizes, s => { return {id:s, name:s};});
            },
            paletteColors(){
                return  {'custom': ['#f2f2f2ff', '#e6e6e6ff', '#d3d3d3ff', '#b3b3b3ff', '#999999ff', '#808080ff']};
            }
        },
        methods:{
            loadFontConfig(){
                const self = this;
                self.fontSelectConfig = {
                fieldTemplate(itemData, itemElement) {
                    let textBoxConfig = {
                        inputAttr: { automation_id: "drp_font" },
                        value: itemData
                    };
                    $("<div />")
                        .dxTextBox(textBoxConfig)
                        .appendTo(itemElement);
                },
                itemTemplate: function(itemData, itemIndex, itemElement) {
                    let $item = $("<div/>").css("font-family", `"${itemData}"`).text(itemData);
                    itemElement.append($item);
                },
                onOpened(e) {
                    let $contentElement = e.component._popup.content();
                    if($contentElement.hasClass(self.dxThemeClass)) return;
                    $contentElement.addClass(self.dxThemeClass);
                }
            };
            },
            loadGridConfig(){
                const self = this;
                self.selectionActions = [
                    { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true }
                ];
                self.gridConfig = {
                    onInitNewRow: self.initNewRow,
                    columns: [
                        {
                            dataField: "watermarkText",
                            width: 250,
                            allowEditing: true,
                        },
                        {
                            dataField: "isDefault",
                            caption: "Default",
                            dataType: "boolean",
                            allowEditing: true,
                            width: 150,
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                }
                self.gridDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.watermarkData.textItems);
                    },
                    update: self.onGridUpdate,
                    insert: self.onGridInsert
                }
            },

            initNewRow(e){
                e.data = {
                    documentTemplateID: this.documentTemplateId,
                    isDefault: false
                };
            },

            onAddTextChoice(){
                this.watermarkTextGrid.addRow();
            },

            onGridUpdate(key, values) {
                const self = this;
                let item = _.find(self.watermarkData.textItems, ti => ti.clientKey === key);
                _.assign(item, values);

                if(item.isDefault === true){
                    _.forEach(self.watermarkData.textItems, ti => {
                        if(ti.clientKey !== item.clientKey)
                            ti.isDefault = false;
                    });
                }

                return Promise.resolve(item);
            },

            onGridInsert(values){
                const self = this;
                let newItem = new DocumentWatermarkTextModel({documentTemplateID: self.documentTemplateId});
                _.assign(newItem, values);

                let defaultItem = _.find(self.watermarkData.textItems, ti => {return ti.isDefault === true;});
                if(_.isNil(defaultItem)) newItem.isDefault = true;

                self.watermarkData.textItems.push(newItem);
                return Promise.resolve(newItem);
            },

            onDeleteAction(e){
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;

                let confirmMessage = items.length === 1 ? "Are you sure you want to delete this watermark text?" : "Are you sure you want to delete these watermark texts?";

                let okHandler = function () {

                    _.forEach(items, i => {
                        let index = _.findIndex(self.watermarkData.textItems, ti => {
                            return ti.clientKey === i.clientKey;
                        });

                        self.watermarkData.textItems.splice(index, 1);

                        if(i.documentWatermarkTextID > 0){
                            self.watermarkData.deletedTextItems.push(i);
                        }
                    });

                    self.watermarkTextGrid.refresh();
                    return true;
                };
                self.$dialog.confirm( "Delete Watermark Text", confirmMessage, okHandler, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            fetchData(){
                const self = this;
                let promise = self.$api.DocumentTemplatesApi.getWatermarks(self.documentTemplateId).then( result => {
                    if(result.watermark)
                        self.watermarkData = new DocumentWatermarksModel(result);
                    else
                        self.watermarkData = new DocumentWatermarksModel({
                            watermark:{documentTemplateID: self.documentTemplateId},
                            fonts: result.fonts,
                            sizes: result.sizes,
                            textItems:[]});
                }).catch(err =>{
                    let msg = err.errorMessage || err.message;
                    self.$toast.error(msg);
                });
                self.$rqBusy.wait(promise);
                return promise;
            },
            save(){
                const self = this;
                let promise = self.$api.DocumentTemplatesApi.saveWatermarks(self.documentTemplateId, self.watermarkData).then( () => {
                    return true;
                }).catch(err =>{
                    let msg = err.errorMessage || err.message;
                    self.$toast.error(msg);
                    return true;
                });
                self.$rqBusy.wait(promise);
                return promise;
            }
        }

    }
</script>

<style lang="scss">
    .rq-watermark-config {
        .grid-container { min-height: 165px; }
    }
</style>